
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Attendance Percentage",
          key: "att_per",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "Selection",
          key: "selection",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          photo: "",
          name: "MAHMUDUR RAHMAN",
          reg_no: "3700000033",
          ref_no: "	2018-2-69-021",
          att_per: "98",
          status: "	Regular",
        },
        {
          id: 2,
          photo: "",
          name: "T.M. MAHBUB SOBHANI",
          reg_no: "3700000034",
          ref_no: "	2018-2-69-022",
          att_per: "98",
          status: "	Regular",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          console.log(response);
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
